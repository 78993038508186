.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container .reactdatepicker_insurance input {
    display: block;
    width: 100%;
}
.react-datepicker__input-container .reactdatepicker_insurance .MuiOutlinedInput-root {
    height: 54px;
}

.myDatePickerWrapper .react-datepicker__input-container .MuiFormControl-marginDense
      {
        margin-top: 0 !important;
    }