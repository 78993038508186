.container {
    font-family: sans-serif;
}

.card_container {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}

.card_field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

.error {
    border: 1px solid red !important;
}

.card_field_50 {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

.card_field_75 {
    width: 75%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

.row {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap;
    /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
}

.col-25 {
    -ms-flex: 25%;
    /* IE10 */
    flex: 25%;
}

.col-50 {
    -ms-flex: 50%;
    /* IE10 */
    flex: 50%;
}

.input,
.text-area,
.select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

.submit {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.message_container {
    border-radius: 5px;
    background: #ffffff;
    font-size: 13px;
    font-family: monospace;
    padding: 20px;
}

#loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
    text-align: center;
}

#loading-image {
    position: absolute;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -150px;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    /* half width of the spinner gif */
    margin-top: -50px;
    /* half height of the spinner gif */
    text-align: center;
    z-index: 1234;
    overflow: auto;
    width: 100px;
    /* width of the spinner gif */
    height: 102px;
    /* height of the spinner gif +2px to fix IE8 issue */
}

.button_container {
    display: flex;
    justify-content: center;
}

/* button:hover {
    background-color: powderblue;
  } */
.button {
    width: 229px;
    height: 49px;
    background: lightblue;
    border: 1px dotted black;
    font-size: 17px;
    color: #3a3a3a;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
}

.btn_small {
    width: 130px;
    height: 39px;
    background: lightblue;
    border: 1px dotted black;
    font-size: 14px;
    color: #3a3a3a;
}

.btn_small:hover {
    background-color: powderblue;
}