body {
    font-family: 'Lato', sans-serif;
}

* {
    outline: none;
}

.header-contents {
    display: flex;
    justify-content: space-between;
    padding: 0px 26px;
    align-items: center;
    height: 80px;
}

.logo-img {
    width: 175px;
}

.login-btns {
    text-decoration: none;
    background-color: #3397F3;
    color: white;
    font-size: 16px;
    padding: 5px 25px 9px 25px;

}

.header-btns a:hover {
    color: white;
}

.mr-10 {
    margin-right: 10px;
}

.mrt-25 {
    margin-top: 25px;
}

.search-space {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f8ff;
    height: calc(100vh - 80px);
}

.search-head {
    font-weight: 700;
    margin-bottom: 25px;
    color: #222121;
}

.search-bar {
    text-align: center;
}

.input-search {
    width: 700px;
    padding: 16px 5px 15px 20px;
    font-size: 14px;
    border: 1px solid #ccc;
    float: left;

}

.search-icon {
    color: #fff;
    font-size: 20px;
}

.search-btn {
    text-decoration: none;
    background-color: #3397F3;
    color: white;
    border: none;
    padding: 13px 10px;
    float: left;
}


/* search result css */

.serachresultpg {
    background-color: #f0f8ff;
    height: calc(100vh - 80px);
}

::-webkit-scrollbar {
    width: 7px;

}

::-webkit-scrollbar-track {
    background: #f1f1f1;

}

::-webkit-scrollbar-thumb {
    background: #b9b9b9;
}

.search-area {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 0.18rem 0.18rem 0px;
}

.search-result-area {
    height: calc(100vh - 274px);
    overflow: auto;
    padding: 10px 20px;
}

.result-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 3px rgb(197 216 234);
    /*border-radius: 5px;*/
}

.result-item .result-item-main {
    color: #3397F3;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
}

.result-item a:hover {
    text-decoration: underline;
}

.result-item p {
    margin-bottom: 0%;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.05px;
}

.sub1 {
    color: #7e7e7e;
    margin-top: 5px;
}

.sub2 {
    color: #249b77;
    margin-top: 5px;
}

.pagination-items {
    display: flex;
    justify-content: right;
    padding: 20px 35px;
    align-items: center;
}

.page-num {
    display: flex;
    align-items: center;
    margin-right: 35px;
}

.page-num p {
    margin-bottom: 0%;
    font-size: 13px;
    margin-right: 25px;
}

.page-num .form-select {
    background-color: transparent;
    border: none;
    width: auto;
}

.form-select :focus {
    box-shadow: none;
}

.page-caption p {
    margin-bottom: 0%;
    margin-right: 35px;
    font-size: 13px;
}

.page-actions {
    display: flex;
}

.page-actions .iconify {
    color: rgb(62 62 62 / 58%);
}

.action-left {
    margin-right: 25px;
}

.page-actions button {
    background-color: transparent;
    border: none;
    padding: 0%;
}

.react-datepicker-popper {
    z-index: 1000 !important;
}


/*  */


/* media-query  */


/* search page */
@media(max-width : 556px) {
    .logo-box {
        padding-top: 10px;
    }

    .header-contents {
        padding: 0px 10px;
        display: block;
        height: 110px;
    }

    .logo-img {
        width: 125px;
    }

    .header-btns {
        margin-top: 15px;
    }

    .input-search {
        width: 86%;
        padding: 9px 5px 12px 20px;
    }

    .search-btn {
        padding: 8px 11px 12px 11px;
        width: 14%;

    }

    .search-icon {
        font-size: 18px;
    }

    .search-bar {
        width: 90%;
    }

    .login-btns {
        font-size: 13px;
        padding: 5px 15px 9px 15px;
    }

    .search-space {
        height: calc(100vh - 110px);
    }
}

@media(min-width : 557px) and (max-width : 767px) {
    .header-contents {
        padding: 0px 10px;
        height: 70px;
    }

    .logo-img {
        width: 125px;
    }

    .input-search {
        width: 90%;
        padding: 9px 5px 12px 20px;
    }

    .search-btn {
        padding: 8px 11px 12px 11px;
        width: 10%;
    }

    .search-icon {
        font-size: 18px;
    }

    .search-bar {
        width: 90%;
    }

    .login-btns {
        font-size: 13px;
        padding: 5px 15px 9px 15px;
    }

    .search-space {
        height: calc(100vh - 70px);
    }
}

/*  */

/* search-result-page */

@media (max-width: 556px) {
    .search-area {
        height: 80px;
    }

    .pagination-items {
        padding: 20px 10px;
        justify-content: center;
    }

    .page-num p {
        font-size: 12px;
        margin-right: 5px;
    }

    .page-num {
        margin-right: 25px;
    }

    .page-num .form-select {
        font-size: 13px;
        background-position: right 0px center;
        padding-right: 13px;
        background-size: 16px 10px;
    }

    .page-caption p {
        font-size: 12px;
    }

    .search-result-area {
        height: calc(100vh - 270px);
    }

    .serachresultpg {
        height: calc(100vh - 110px);
    }

}


@media(min-width : 557px) and (max-width : 767px) {
    .serachresultpg {
        height: calc(100vh - 70px);
    }

    .search-area {
        height: 80px;
    }

    .search-result-area {
        height: calc(100vh - 234px);
    }

}

/*  */
/* Free trial style css  ----------------------------- start */

body {
    font-family: 'Lato', sans-serif;
}

* {
    outline: none;
}

.header-contents {
    display: flex;
    justify-content: space-between;
    padding: 0px 26px;
    align-items: center;
    height: 80px;
}

.logo-img {
    width: 175px;
}

.login-btns {
    text-decoration: none;
    background-color: #3397F3;
    color: white;
    font-size: 16px;
    padding: 5px 25px 9px 25px;

}

.header-btns a:hover {
    color: white;
}

.mr-10 {
    margin-right: 10px;
}

.mrt-25 {
    margin-top: 25px;
}

.search-space {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f8ff;
    height: calc(100vh - 80px);
}

.search-head {
    font-weight: 700;
    margin-bottom: 25px;
    color: #222121;
}

.search-bar {
    text-align: center;
}

.input-search {
    width: 700px;
    padding: 12px 5px 15px 20px;
    font-size: 14px;
    border: 1px solid #ccc;
    float: left;

}

.search-icon {
    color: #fff;
    font-size: 20px;
}

.search-btn {
    text-decoration: none;
    background-color: #3397F3;
    color: white;
    border: none;
    padding: 13px 10px;
    float: left;
}


/* search result css */

.serachresultpg {
    background-color: #f0f8ff;
    height: calc(100vh - 80px);
}

::-webkit-scrollbar {
    width: 7px;

}

::-webkit-scrollbar-track {
    background: #f1f1f1;

}

::-webkit-scrollbar-thumb {
    background: #b9b9b9;
}

.search-area {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 0.18rem 0.18rem 0px;
}

.search-result-area {
    height: calc(100vh - 274px);
    overflow: auto;
    padding: 10px 20px;
}

.result-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 3px rgb(197 216 234);
    /*border-radius: 5px;*/
}

.result-item .result-item-main {
    color: #3397F3;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
}

.result-item a:hover {
    text-decoration: underline;
}

.result-item p {
    margin-bottom: 0%;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.05px;
}

.sub1 {
    color: #7e7e7e;
    margin-top: 5px;
}

.sub2 {
    color: #249b77;
    margin-top: 5px;
}

.pagination-items {
    display: flex;
    justify-content: right;
    padding: 20px 35px;
    align-items: center;
}

.page-num {
    display: flex;
    align-items: center;
    margin-right: 35px;
}

.page-num p {
    margin-bottom: 0%;
    font-size: 13px;
    margin-right: 25px;
}

.page-num .form-select {
    background-color: transparent;
    border: none;
    width: auto;
}

.form-select :focus {
    box-shadow: none;
}

.page-caption p {
    margin-bottom: 0%;
    margin-right: 35px;
    font-size: 13px;
}

.page-actions {
    display: flex;
}

.page-actions .iconify {
    color: rgb(62 62 62 / 58%);
}

.action-left {
    margin-right: 25px;
}

.page-actions button {
    background-color: transparent;
    border: none;
    padding: 0%;
}


/*  */


/* media-query  */


/* search page */
@media(max-width : 556px) {
    .logo-box {
        padding-top: 10px;
    }

    .header-contents {
        padding: 0px 10px;
        display: block;
        height: 110px;
    }

    .logo-img {
        width: 125px;
    }

    .header-btns {
        margin-top: 15px;
    }

    .input-search {
        width: 86%;
        padding: 9px 5px 12px 20px;
    }

    .search-btn {
        padding: 8px 11px 12px 11px;
        width: 14%;

    }

    .search-icon {
        font-size: 18px;
    }

    .search-bar {
        width: 90%;
    }

    .login-btns {
        font-size: 13px;
        padding: 5px 15px 9px 15px;
    }

    .search-space {
        height: calc(100vh - 110px);
    }
}

@media(min-width : 557px) and (max-width : 767px) {
    .header-contents {
        padding: 0px 10px;
        height: 70px;
    }

    .logo-img {
        width: 125px;
    }

    .input-search {
        width: 90%;
        padding: 9px 5px 12px 20px;
    }

    .search-btn {
        padding: 8px 11px 12px 11px;
        width: 10%;
    }

    .search-icon {
        font-size: 18px;
    }

    .search-bar {
        width: 90%;
    }

    .login-btns {
        font-size: 13px;
        padding: 5px 15px 9px 15px;
    }

    .search-space {
        height: calc(100vh - 70px);
    }
}

/*  */

/* search-result-page */

@media (max-width: 556px) {
    .search-area {
        height: 80px;
    }

    .pagination-items {
        padding: 20px 10px;
        justify-content: center;
    }

    .page-num p {
        font-size: 12px;
        margin-right: 5px;
    }

    .page-num {
        margin-right: 25px;
    }

    .page-num .form-select {
        font-size: 13px;
        background-position: right 0px center;
        padding-right: 13px;
        background-size: 16px 10px;
    }

    .page-caption p {
        font-size: 12px;
    }

    .search-result-area {
        height: calc(100vh - 270px);
    }

    .serachresultpg {
        height: calc(100vh - 110px);
    }

}


@media(min-width : 557px) and (max-width : 767px) {
    .serachresultpg {
        height: calc(100vh - 70px);
    }

    .search-area {
        height: 80px;
    }

    .search-result-area {
        height: calc(100vh - 234px);
    }

}

.trialsection {
    position: absolute;
    right: 0;
    bottom: 10px;
}

/* .paypal-buttons {
    max-height: 300px;
    overflow-y: auto;
} */

/* .card-fields-container {
    overflow-y: auto !important;
    height: 200px !important;
} */

/* Free trial style css ----------------------------------- end */