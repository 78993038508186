html,
body {
  font-family: "Lato", sans-serif;
}

legend {
  float: unset;
}

.validity_wrapper {
  height: 50px;
  border: 1px solid rgba(139, 141, 143, 0.5);
  box-shadow: 6px 81px 49px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px 15px;
  background-color: white;
}

.validity_wrapper h6 {
  font-size: 11px;
  width: 70px;
  margin: 0px 10px;
  font-weight: 700;
  line-height: 12px;
}

.validity_calendar {
  width: 35px;
}

.validity_wrapper h6 span {
  font-size: 12px;
  font-weight: 400;
  color: #de5555;
  margin-top: 2px;
  display: block;
}

.btn_modal_open {
  border: none;
  background-color: #de5555;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  border-radius: 6px;
  padding: 0px 15px;
  transition: all 0.1s linear;
  position: relative;
  top: 0%;
}

.btn_modal_open:hover {
  position: relative;
  top: -2px;
  transition: all 0.1s linear;
}

.upgrade_modal .modal-header {
  height: 190px;
  justify-content: center;
  background: linear-gradient(90deg, #e0a085 0%, #d17171 99.53%);
}

.icon_upgrade {
  color: #fff;
  font-size: 45px;
}

.vector_time {
  margin: 30px 0px 20px 0px;
  width: 200px;
}

.upgrade_modal .modal-header .head_wrap {
  text-align: center;
}

.upgrade_modal .modal-header .head_wrap h6 {
  color: white;
  font-size: 38px;
  font-weight: 300;
  margin-top: 10px;
}

.upgrade_modal .modal-dialog {
  min-width: 950px;
}

.upgrade_modal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 0%;
}

.modal_body_wrap {
  text-align: center;
}

.modal_body_wrap h6 {
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 400;
}

.modal_body_wrap p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
}

.btn_amount {
  background: #3397f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 62px;
  color: white;
  text-decoration: none;
  border: none;
  display: inline-block;
  font-size: 18px;
  padding: 5px 35px;
  margin-bottom: 5px;
  font-weight: 500;
  width: 155px;
}

.btn_amount:hover {
  color: #fff;
}

.tag_logout {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  color: #3397f3;
  font-weight: 400;
}

.upgrade_modal .modal-content {
  border: none !important;
}

.tag_logout:hover {
  color: #3397f3;
}

@media (max-width: 575px) {
  .upgrade_modal .modal-header .head_wrap h6 {
    font-size: 25px;
  }

  .modal_body_wrap h6 {
    font-size: 20px;
  }

  .modal_body_wrap p {
    font-size: 12px;
  }

  .btn_amount {
    font-size: 16px;
  }

  .tag_logout {
    font-size: 12px;
  }

  .upgrade_modal .modal-dialog {
    min-width: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .upgrade_modal .modal-header .head_wrap h6 {
    font-size: 25px;
  }

  .modal_body_wrap h6 {
    font-size: 18px;
  }

  .modal_body_wrap p {
    font-size: 14px;
  }

  .btn_amount {
    font-size: 14px;
  }

  .tag_logout {
    font-size: 12px;
  }

  .upgrade_modal .modal-dialog {
    min-width: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .upgrade_modal .modal-header .head_wrap h6 {
    font-size: 38px;
  }

  .modal_body_wrap h6 {
    font-size: 26px;
  }

  .modal_body_wrap p {
    font-size: 18px;
  }

  .upgrade_modal .modal-dialog {
    min-width: auto;
  }

  .btn_amount {
    font-size: 18px;
  }

  .tag_logout {
    font-size: 14px;
  }

  .upgrade_modal .modal-dialog {
    min-width: 650px;
  }
}

.successfulmodal .modal-header {
  height: 170px;
  justify-content: center;
  background: linear-gradient(90deg, #85e09f 0%, #71d19d 99.53%);
}

.failuremodal .modal-header {
  height: 170px;
  justify-content: center;
  background: linear-gradient(90deg, #85e09f 0%, #71d19d 99.53%);
}

.successfulmodal .head_wrap img {
  width: 40px;
}

.failure .head_wrap img {
  width: 40px;
}

.successfulmodal .vector_time {
  margin: 30px 0px 20px 0px;
  width: 100px;
}

.failure .vector_time {
  margin: 30px 0px 20px 0px;
  width: 100px;
}

.successfulmodal .modal-body {
  height: 345px;
}

.failure .modal-body {
  height: 345px;
}

.paymentmodal .paymentwrapper {
  height: 545px;
  width: 100%;
  background-color: white;
  padding-top: 10px;
}

.headerlogo img {
  width: 170px;
}

.close {
  height: 15px;
  /* margin-left:575px; */
  cursor: pointer;
}


.btn-close {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 10px;

}

.logo_paypal {
  width: 110px;
}

.amountwraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 25px;
}

.amountpay {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.amountpay h6 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
}

.amountpay .iconify {
  font-size: 15px;
  font-size: 17px;
  margin-right: 5px;
}

.itemhead p {
  font-size: 14px;
  color: #808080;
}

.itembody {
  max-height: 285px;
  overflow-y: auto;
}

.payment_contents {
  padding: 30px 25px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.cardtype_imgs img {
  width: 200px;
}

.cardtype_imgs {
  display: flex;
  height: 40px;
  width: 100%;
  background-color: #f2f3f4;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.cardnum input,
.csc input {
  height: 40px;
  width: 100%;
  border: 1px solid #d3d1d1;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  outline: none !important;
}

.cardnum input::placeholder,
.csc input::placeholder {
  color: rgb(209, 209, 209);
  font-size: 14px;
}

.cardnum {
  margin-bottom: 5px;
}

.month_year .form-select {
  width: 118px;
  height: 40px;
  border: 1px solid #d3d1d1;
}

.form-select:focus {
  outline: 0;
  box-shadow: none;
  border-color: #e1e1e1;
}

.month_year {
  display: flex;
}

.expire {
  float: left;
  width: 58%;
}

.item_lable {
  margin-bottom: 5px;
  font-size: 15px;
}

.select1 {
  border-right: none !important;
  border-radius: 0.25rem 0 0 0.25rem;
}

.select2 {
  border-radius: 0 0.25rem.25rem 0;
}

.csc {
  width: 40%;
  float: right;
  position: relative;
}

.csc img {
  position: absolute;
  top: 32px;
  right: 10px;
  width: 25px;
}

.pay_vector {
  width: 200px;
  margin-bottom: 20px;
}

.img-wrapper {
  width: 100%;
  text-align: center;
}

.paymentcancel {
  border: 1px solid #3397F3;
  color: #3397F3;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 500;
  background-color: #fff;
  padding: 5px 10px;
}

.makepayment {
  border: 1px solid #3397F3;
  background-color: #3397F3;
  color: #fff;
  text-decoration: none;
  margin-right: 0;
  font-weight: 500;
  padding: 5px 10px;
}

.payment_btn {
  text-align: right;
  margin-top: 30px;
  display: inline-block;
  width: 100%;
}

.img-wrapper h4 {
  width: 300px;
  margin: 0 auto;
}

.img-wrapper p {
  width: 300px;
  margin: 0 auto;
  line-height: 20px;
  font-size: 14px;
  color: #808080;
}

/* payment modal */
@media (max-width: 575px) {
  .pay_vector {
    display: none;
  }

  .img-wrapper h4 {
    display: none;
  }

  .img-wrapper p {
    display: none;
  }

  .payment_btn {
    margin-top: 10px;
  }

  .paymentcancel,
  .makepayment {
    margin-right: 28px;
  }

  .payment_contents {
    padding: 30px 0px;
  }

  .csc {
    width: 35%;
  }

  .expire {
    float: left;
    width: 60%;
  }

  .csc img {
    position: absolute;
    top: 34px;
    right: 10px;
    width: 19px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .pay_vector {
    display: none;
  }

  .img-wrapper h4 {
    display: none;
  }

  .img-wrapper p {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .csc {
    width: 35%;
    float: right;
    position: relative;
  }

  .csc img {
    position: absolute;
    top: 34px;
    right: 10px;
    width: 20px;
  }

  .pay_vector {
    width: 180px;
    margin-bottom: 20px;
  }

  .img-wrapper h4 {
    width: 290px;
    margin: 0 auto;
  }
}

a {
  text-decoration: none !important;
}
